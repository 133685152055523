<template>
  <div>
    <BasePageHeader title="Rankings" />
    <div class="rankings-message" v-if="!ui.loading && ui.hideRankings">
      Unlock this screen by SUBMITTING or FUNDING {{ rankingUnits }} deals.
    </div>
    <div class="page-tabs d-flex justify-content-between" :class="[ui.hideRankings ? 'blured' : '']">
      <ul class="tab-list" v-if="!ui.hideRankings">
        <li :class="{ active: isActive('ranking-agents') }" @click="activeTab = 'ranking-agents'">
          {{ ui.hideRankings ? 'Tab' : 'Agents' }}
        </li>
        <li :class="{ active: isActive('ranking-processors') }" @click="activeTab = 'ranking-processors'" >
          {{ ui.hideRankings ? 'Tab' : 'Processors' }}
        </li>
      </ul>
      <div class="d-flex items-center">
        <multiselect
          v-if="!ui.hideRankings"
          v-model="viewType"
          class="me-2"
          placeholder="Sort by"
          :options="viewTypes"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="false"
          :show-labels="false"
          @select="changeViewType">
        </multiselect>
        <multiselect
          v-if="!ui.hideRankings"
          v-model="tableFilter"
          placeholder="Select Filter"
          :options="tableFilters"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="false"
          :show-labels="false"
          label="label"
          track-by="value"
          @select="changeFilter">
        </multiselect>
      </div>
    </div>
    <PageSpinner v-if="ui.loading" />
    <div class="page-content" :class="[ui.hideRankings ? 'blured' : '']" v-else>
      <div class="alert alert-warning w-100 mt-2" v-if="isGranted('ROLE_CEO') && ui.isEnabled === '0'" >
        Rankings currently disabled.
      </div>
      <transition name="component-fade" mode="out-in">
        <component
          v-bind:is="activeTab"
          :agentRanks="agentsRanks"
          :tableFilter="tableFilter.value"
          :processorRanks="processorsRanks"
          :viewType="viewType">
        </component>
      </transition>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from "@/components/pageSpinner";
import RankingAgents from "./includes/RankingAgents";
import RankingProcessors from "./includes/RankingProcessors";
import BasePageHeader from "../../components/BasePageHeader";

export default {
  name: "Rankings",
  components: {BasePageHeader, Multiselect, PageSpinner, RankingProcessors, RankingAgents },
  data() {
    return {
      ui: {
        loading: false,
        isEnabled: "1",
        hideRankings: true,
        loadedFirstTime: true
      },
      activeTab: "ranking-agents",
      rankingUnits: 0,
      tableFilter: {},
      tableFilters: [
        {
          label: "This Month",
          value: "MTD"
        },
        {
          label: "Last Month",
          value: "lastMonth"
        },
        {
          label: "This Year",
          value: "YTD"
        },
        {
          label: "Last Year",
          value: "lastYear"
        }
      ],
      viewType: 'Submissions',
      viewTypes: ['Submissions', 'Pre-Approvals'],
      agentsRanks: [],
      processorsRanks: [],
      branchId: null,
    };
  },
  methods: {
    isActive(tabName) {
      return this.activeTab === tabName;
    },
    changeFilter(value) {
      this.tableFilter = value;
      this.getRanks();
    },
    changeViewType(value) {
      this.viewType = value;
      this.getRanks();
    },
    getRanks() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/rankings`, {
          params: {
            period: this.tableFilter.value,
            sortBy: this.viewType
          }
        })
        .then(res => {
          this.agentsRanks = res.data.agentsRanks;
          this.processorsRanks = res.data.processorsRanks;
          this.ui.isEnabled = res.data.isEnabled;
          this.ui.hideRankings = res.data.hideRankings
          this.rankingUnits = res.data.rankingUnits
          this.branchId = res.data.branchId
          if (this.branchId === 14 && this.ui.loadedFirstTime) { // For San Diego Branch
            this.ui.loadedFirstTime = false;
            this.viewType = 'Pre-Approvals';
          }
        })
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.loading = false));
    }
  },
  created() {
    this.tableFilter = this.tableFilters[0];
    this.getRanks();
  }
};
</script>

<style lang="scss">
.blured {
  filter: blur(10px);
}
.rankings-message {
  position: absolute;
  top: 50%;
  left: 50%;
}
.multiselect {
  margin: auto 0;
  max-width: 200px;
  width: 100%;
  height: 36px;
  min-height: 36px;
  &__tags {
    width: 200px;
    min-height: 36px;
    max-height: 36px;
    padding: 6px 40px 0 8px;
  }
  &__select {
    height: 36px;
    max-height: 36px;

    &::before {
      top: 5%;
    }
  }
}
</style>
