<template>
  <div>
    <div class="table-block">
      <table class="table mt-3">
        <thead>
          <tr>
            <th class="text-center">Rank</th>
            <th>Processor</th>
            <th>Total Fundings</th>
            <th v-if="isGranted('ROLE_CEO')">Loans in Pipeline</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="processorRanks.length === 0">
            <td align="center" colspan="4">No Records Found</td>
          </tr>
          <tr v-for="(processor, index) in processorRanks" :key="index">
            <td class="text-center" :data-id="index">
              <img v-if="index == 0" src="@/assets/icons/icon-rank-1.svg" alt="Rank 1" />
              <img v-else-if="index == 1" src="@/assets/icons/icon-rank-2.svg" alt="Rank 2" />
              <img v-else-if="index == 2" src="@/assets/icons/icon-rank-3.svg" alt="Rank 3" />
              <span v-else>{{ index + 1 }}</span>
            </td>
            <td>{{ processor.processorName }}</td>
            <td>{{ processor.totalUnits }}</td>
            <td v-if="isGranted('ROLE_CEO')">{{ processor.loansInPipeline }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingProcessors",
  props: {
    processorRanks: {
      type: Array
    },
    tableFilter: {
      type: String,
      default: 'MTD'
    },
    viewType: {
      type: String,
      default: 'Submissions'
    },
  }
};
</script>

<style lang="scss" scoped>
.table-block {
  table {
    thead {
      tr {
        th {
          padding-left: 0;
          &:first-child {
            width: 5%;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 0;
          &:first-child {
            width: 5%;
          }
        }
      }
    }
  }
}
</style>
