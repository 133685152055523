<template>
  <div>
    <div class="table-block">
      <table class="table mt-3">
        <thead>
          <tr>
            <th class="text-center">Rank</th>
            <th>Agent</th>
            <th style="text-align: center" v-if="tableFilter === 'MTD' && viewType !== 'Pre-Approvals'">Submissions (units)</th>
            <th style="text-align: center" v-if="tableFilter === 'MTD'">Pre-approvals</th>
            <th style="text-align: center" v-if="tableFilter === 'MTD' && viewType !== 'Pre-Approvals'">Submissions ($)</th>
            <th style="text-align: center">Fundings (units)</th>
            <th style="text-align: center">Fundings ($)</th>
            <th style="text-align: center" v-if="isGranted('ROLE_CEO') && tableFilter === 'MTD'">Loans in Pipeline</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="agentRanks.length === 0">
            <td align="center" colspan="7">No Records Found</td>
          </tr>
          <tr v-for="(agent, index) in agentRanks" :key="index" @click="toggleTableRow(agent)">
            <td class="text-center" :data-id="index">
              <img v-if="index == 0" src="@/assets/icons/icon-rank-1.svg" alt="Rank 1" />
              <img v-else-if="index == 1" src="@/assets/icons/icon-rank-2.svg" alt="Rank 2" />
              <img v-else-if="index == 2" src="@/assets/icons/icon-rank-3.svg" alt="Rank 3" />
              <span v-else>{{ index + 1 }}</span>
            </td>
            <td>
              <span :style="{cursor: viewType === 'Submissions' ? 'pointer' : 'default'}">{{ agent.agentName }}</span>
              <div v-if="agent.showSource && tableFilter === 'MTD'">
                <div v-for="(total, source, index) in agent.submissionsSource" :key="index" style="color: #000000; opacity: 0.6">
                  {{ source }}
                </div>
              </div>
            </td>
            <td style="text-align: center" v-if="tableFilter === 'MTD' && viewType !== 'Pre-Approvals'">
              {{ agent.submissionsUnits }}
              <div v-if="agent.showSource && tableFilter === 'MTD'">
                <div v-for="(total, source, index) in agent.submissionsSource" :key="index" style="color: #000000; opacity: 0.6">
                  {{ total }}
                </div>
              </div>
            </td>
            <td style="text-align: center" v-if="tableFilter === 'MTD'">{{ agent.preApprovedUnits }}</td>
            <td style="text-align: center" v-if="tableFilter === 'MTD' && viewType !== 'Pre-Approvals'">{{ convertToCurrency(agent.submissionsSum, 0) }}</td>
            <td style="text-align: center">{{ agent.fundingsUnits }}</td>
            <td style="text-align: center">{{ convertToCurrency(agent.fundingsSum, 0) }}</td>
            <td style="text-align: center" v-if="isGranted('ROLE_CEO') && tableFilter === 'MTD'">{{ agent.loansInPipeline }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingAgents",
  props: {
    agentRanks: {
      type: Array
    },
    tableFilter: {
      type: String,
      default: 'MTD'
    },
    viewType: {
      type: String,
      default: 'Submissions'
    },
  },
  methods: {
    toggleTableRow(row) {
      if (this.viewType === 'Submissions') {
        this.agentRanks = this.agentRanks.filter(agent => {
          if (row.agentId === agent.agentId) {
            agent.showSource = !agent.showSource
          }

          return agent;
        });
      }
    },
  },
  mounted() {
    this.agentRanks.map(agent => {
      agent.showSource = false;
      return agent;
    });
  }
};
</script>

<style lang="scss" scoped>
.table-block {
  table {
    thead {
      tr {
        th {
          padding-left: 0;
          &:first-child {
            width: 5%;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 0;

          &:first-child {
            width: 5%;
          }
        }
      }
    }
  }
}
</style>
